/*--------------------------------------------------------------
## Header Component Style
--------------------------------------------------------------*/

.site-header {
    display: flex;
}

.site-header__banner {
    position: fixed;
    display: inline-flex;
    justify-content: space-between;
    align-content: center;
    font-size: 14px;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: linear-gradient(to right, #2be0b6, #1fcdee);
    color: black;
    z-index: 999999;
    translate: 0 -50px;
    transition: all 0.5s ease-in-out;
    padding: 5px 20px;
    opacity: 0;

    &__text {
        flex: 5;
        text-align: center;
    }

    &-close {
        cursor: pointer;
    }

    &.visible {
        translate: 0 0;
        opacity: 1;
    }

    a {
        text-decoration: underline;
        color: inherit;

        &:hover {
            color: blue;
        }
    }

    @include mq($md) {
        font-size: 12px;
    }

    @include mq($sm) {
        font-size: 10px;
    }
}

.site-header__container {
    display: flex;
    padding: 15px 17px 15px 24px;
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    background-color: $white;
    align-items: center;
    max-height: 70px;
    transition: 0.5s ease;
    @include mq(1200px) {
        padding: 21px 17px 21px 24px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1000px) {
        width: 1000px;
        position: absolute;
    }
    @media screen and (max-width: 767px) {
        padding: 17px 17px 17px 24px;
        height: 70px;
        .filters__property-types {
            display: none;
        }
    }
}

.site-header__nav-wrap {
    display: flex;
    align-items: center;
    margin-left: auto;
    position: relative;
    margin-left: auto;
    @media screen and (max-width: 767px) {
        margin-left: 0;
    }
}

.site-header__upgrade {
    position: relative;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid #c9cccf;
    color: $boulder2;
    border-radius: 3px;
    margin: 30px 30px 30px 0;
    padding: 10px 10px 10px;
    transition: $dur $ease;

    @media screen and (max-width: 767px) {
        margin-right: 0;
        margin-left: 19px;
    }
    // @media screen and (min-width: 767px) {
    //     &:hover {
    //         text-decoration: underline;
    //         .site-header__popups-popup {
    //             opacity: 1;
    //             visibility: visible;
    //             pointer-events: all;
    //         }
    //     }
    // }

    &:hover {
        background-color: $jungle-green2;
        border-color: $jungle-green2;
        color: $white;
        text-decoration: none;
        cursor: pointer;
    }
}

.site-header__popups-popup {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    padding: 21px 20px 19px;
    background: $alabaster-second;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    z-index: 1;
    width: 490px;
    right: -113px;
    height: 220px;
    top: 35px;
    transition: 0.4s ease;
    display: flex;
    flex-direction: column;
    h3 {
        font-family: $font-third;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: $blue-whale;
        margin-bottom: 20px;
    }
    ul {
        margin-left: 20px;
        margin-bottom: 20px;
        li {
            list-style-type: disc;

            font-family: $font-primary;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $blue-whale;
        }
    }
    .site-header__popup_bttns {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        button {
            width: 204px;
            height: 46px;
        }
        .site-header__details-hover {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 204px;
            height: 46px;
            font-family: $font-primary;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 26px;
            color: $blue-whale;
            background-color: transparent;
            border: 2px solid $blue-whale;
            border-radius: 6px;
        }

        .site-header__member-hover {
            font-family: $font-primary;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 26px;
            background: $blue-whale;
            border-radius: 6px;
            color: $white;
            border: none;
        }
    }

    @include mq($sm) {
        width: 350px;
        padding: 15px;
        left: 25px;
    }

    @include mq(400px) {
        width: 270px;
        padding: 10px;
    }
}

.site-header__show-property-search {
    display: none;
    @include mq($md) {
        display: block;
        margin-left: auto;
        svg {
            width: 24px;
            height: 24px;
            color: $blue-whale;
        }
        &.open svg {
            color: $jungle-green2;
        }
    }
}

.site-header__member-cta {
    width: 100%;
    margin-top: 8px;
    margin-bottom: -1px;
    display: flex;
    justify-content: center;
    button {
        width: 204px;
        height: 46px;
        font-family: $font-primary;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        background-color: $blue-whale;
        border-radius: 6px;
        color: $white;
        border: none;
    }
}

.site-header__not_logged_in {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    button {
        width: 204px;
        height: 46px;
        font-family: $font-primary;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        background-color: $blue-whale;
        border-radius: 6px;
        color: $white;
        border: none;
    }
}

.header__navigation-dropdown {
    margin-left: 30px;

    @include mq($lap-big) {
        margin-left: 15px;
    }
}

.sidebar__logo {
    max-width: 241px;
    margin-bottom: 37px;
}

.site-header__nav {
    position: fixed;
    height: 100vh;
    max-width: 350px;
    width: 100%;
    background-color: $green-light;
    right: 0;
    top: 0;
    z-index: 9999;
    transform: translateX(100%);
    transition: $dur $ease;
    opacity: 0;
    padding: 70px 20px;

    &.active {
        transform: none;
        opacity: 1;

        .hamburger-active {
            opacity: 1;
            display: block;
        }
    }
}

.page-overlay {
    position: fixed;
    background-color: transparent;
    top: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    transition: $dur $ease;
    pointer-events: none;
    transform: translateX(-100%);
    transition: $dur $ease;
    opacity: 0;
    z-index: 999;

    &.active {
        transform: none;
        opacity: 1;
        pointer-events: all;
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
}

// navigation
.site-nav__list {
    padding: 6px 0;
}

.menu-item {
    a {
        display: block;
        font-family: $font-primary;
        font-size: 14px;
        color: $blue-whale;
        line-height: 30px;
        transition: $dur $ease;
        font-weight: 500;
        padding: 5px 20px;
        @include hover {
            color: $blue-whale;
            background-color: rgba(150, 204, 212, 0.15);
        }
    }
}

.menu-item--back {
    display: none;
    @include mq($md) {
        display: block;
    }
}

// hamburger
.site-header__hamburger {
    position: relative;
    transition: $dur $ease;
    display: block !important;
    margin-left: auto;
    z-index: 1000;
    cursor: pointer;
    > svg {
        font-size: 24px;
        color: $blue-whale;
        &:nth-of-type(2) {
            display: none;
        }
    }
    .main-menu {
        right: -1px;
    }
    &::before {
        display: none;
        content: "";
        position: absolute;
        width: 50px;
        height: 50px;
        top: 0;
        right: 0;
    }
    @include hover {
        color: $jungle-green;
        &::before {
            display: block;
        }
        @include hover {
            .site-header__user-dropdown-wrap {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
    &.open {
        .site-header__user-dropdown-wrap {
            opacity: 1;
            transform: scale(1);
        }
        > svg {
            color: $jungle-green2;
        }
    }
    @media screen and (max-width: 767px) {
        position: relative;
        margin-left: 19px;
        padding: 3px 5px;
        > svg {
            transition: $dur $ease;
            &:nth-of-type(2) {
                display: block;
                top: 2px;
                left: 8px;
                position: absolute;
                opacity: 0;
                pointer-events: none;
            }
        }
        &.open > svg {
            &:nth-of-type(1) {
                opacity: 0;
                pointer-events: none;
            }
            &:nth-of-type(2) {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}

.hamburger-active {
    display: none;
    cursor: pointer;
    width: 30px;
    height: 19px;
    right: 20px;
    top: 25px;
    position: absolute;

    span {
        width: 100%;
        height: 3px;
        position: absolute;
        display: block;
        background-color: $blue-whale;
        pointer-events: none;
        border-radius: 20px;
    }

    span {
        top: 50%;
        width: 100%;
        margin-top: -1.5px;
        border-radius: 20px;
        transform: rotate(45deg);
        transition: $dur transform $dur $ease;

        &::before {
            content: "";
            background-color: $blue-whale;
            width: 100%;
            height: 3px;
            position: absolute;
            left: 0;
            z-index: 1;
            border-radius: 20px;
            transform: rotate(-90deg);
            transition: $dur transform $dur $ease;
            opacity: 1;
        }
    }
}

// user dropdown
.site-header__user-dropdown-wrap {
    box-shadow:
        0px 4px 5px rgba(0, 0, 0, 0.14),
        0px 1px 10px rgba(0, 0, 0, 0.12),
        0px 2px 4px -1px rgba(0, 0, 0, 0.2);
    background-color: white;
    position: absolute;
    top: 100%;
    right: 0;
    // max-width: 226px;
    min-width: 226px;
    // width: 100%;
    border-radius: 8px;
    opacity: 0;
    transform: scale(0);
    transition: $dur $ease;
    z-index: 9999;
    margin-top: 8px;

    @media screen and (max-width: 767px) {
        &.main-menu {
            position: fixed;
            top: 70px;
            left: 0;
            right: 0;
            width: 100%;
            max-width: 100%;
            box-shadow: none;
            height: calc(100vh - 70px);
            text-align: center;
            margin-top: 0;
            svg {
                margin-right: 6px;
                height: 14px;
                color: $blue-whale;
            }
            a {
                padding: 21px;
                font-weight: 400;
                line-height: 14px;
            }
        }
    }
}

.site-header__user-dropdown-info {
    padding: 19px 20px 12px;
    cursor: context-menu;

    @include mq($phone) {
        padding: 15px 15px 8px;
    }
}

.site-header__user-dropdown__btn-icon {
    svg {
        height: 14px;
    }
}

.site-header__user-dropdown__name {
    font-family: $font-primary;
    font-size: 14px;
    margin-bottom: 4px;
    line-height: 14px;
    font-weight: 500;
    color: $blue-whale;
}

.site-header__user-dropdown__email {
    color: $boulder;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    margin-bottom: 8px;
}

.site-header__user-dropdown__status {
    background: rgba(34, 170, 148, 0.22);
    border-radius: 10px;
    color: $jungle-green2;
    padding: 2px 8px;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    &.trial {
        background: rgba(250, 108, 61, 0.22);
        color: $burn-orange;
    }
    &.free {
        background: rgba(51, 102, 204, 0.22);
        color: #3366cc;
    }
}

.site-header__user-dropdown__btn {
    padding: 21px 20px;
    background-color: $white;
    transition: $dur $ease;
    color: $boulder;
    border: none;
    width: 100%;
    font-weight: 500;
    display: flex;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 14px;

    svg {
        fill: $boulder !important;
        color: $boulder !important;
        transition: $dur $ease;
        margin-right: 12px;
        width: 16px;
        height: 14px;
    }

    @include hover {
        color: $blue-whale;
        background-color: rgba(150, 204, 212, 0.15);

        svg {
            fill: $blue-whale !important;
            color: $blue-whale !important;
        }
    }
}
.site-header__user {
    img {
        height: 28px;
        border-radius: 25px;
    }
}

.site-header__navigation-dropdown {
    &:hover {
        .site-header__user-dropdown-wrap {
            opacity: 1;
            transform: scale(1);
        }
    }
}

// switcher
.checkbox-dropdown-header {
    .checkbox-dropdown__switch {
        display: flex;
        align-items: center;
        font-family: $font-primary;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: $blue-whale;

        @include mq($lap-big) {
            left: 25px;
        }
    }
    .ant-switch {
        background-color: $catskill-white;
        background: $catskill-white;
        border: 1px solid $catskill-white;
        border-radius: 11px;
        margin-right: 16px;
        min-width: 39px;

        &.ant-switch-checked {
            background: $jungle-green2;

            .ant-switch-handle {
                background: $white;

                &::before {
                    background: $white;
                    background-color: $white;
                }
            }
        }
    }

    .ant-switch-handle {
        width: 14px !important;
        height: 14px;
        border-radius: 11px;
        top: 3px;
        left: 5px;
        background: $gray;

        &::before {
            background: $gray;
            background-color: $gray;
        }
    }

    .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 18px - 2px) !important;
    }
}

.header__filters-btn {
    background-color: $white;
    border: 1px solid #c9cccf;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $boulder2;
    width: 89px;
    padding: 8px 0;
    transition: $dur $ease;
    flex-shrink: 0;
    //margin-right: 62px;
    @include hover {
        background-color: $jungle-green2;
        border-color: $jungle-green2;
        color: $white;
    }
    &.active {
        background-color: $blue-whale;
        color: $white;
    }
    &.disabled {
        color: #a2acba;
        cursor: no-drop;
        background-color: $white;
        border-color: #c9cccf;
    }

    @include mq(1060px) {
        margin-right: 10px; //42px;
    }

    @include mq(1000px) {
        margin-right: 0;
    }
    @media screen and (max-width: 767px) {
        display: none;
    }
}
