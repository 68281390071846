/*--------------------------------------------------------------
## Sidebar Component Style
--------------------------------------------------------------*/
.site-sidebar {
    position: fixed;
    top: 70px;
    z-index: 2;
    width: 540px;
    background-color: $white;
    height: calc(100vh - 70px);
    transition: all 0.5s ease;
    overflow: hidden;

    @include browser-support(firefox) {
        width: 560px;
    }

    div.ant-collapse-header {
        padding: 16px 14px !important;

        .ant-collapse-header-text {
            color: $blue-whale;
        }
        @include hover {
            background-color: #eff7f9;
        }
    }

    .ant-collapse-content {
        transition: all 0.3s ease;
    }

    &.collapsed {
        width: 60px;
        .site-sidebar__collapse {
            overflow: unset;
            // pointer-events: none;
        }
        .ant-collapse-header {
            padding-left: 18px !important;
        }
        .filters__dropdowns-icon {
            margin-right: 17px;
        }
        .legend,
        .filters__dropdown-icon,
        .ant-collapse-content,
        .filters__dropdowns-text,
        .site-sidebar__count-title span {
            opacity: 0;
            pointer-events: none;
        }
        .site-sidebar__count {
            padding: 16px 14px 0 18px;
        }
        .site-sidebar__count-title svg {
            margin-top: -2px;
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 1000px) {
        position: absolute;
    }
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.site-sidebar__collapse {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 70px);
    width: 100%;
    overflow-y: scroll;
    padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box; /* So the width will be 100% + 17px */
    @include scroll-style;
}

.site-sidebar__count {
    position: absolute;
    background: white;
    bottom: -14px;
    left: 0;
    width: 100%;
    padding: 16px 14px 0;
    border-top: 1px solid $clear-cloud;
    transition: 0.3s ease;
    height: 201px;
}

.filters__dropdowns-text {
    //width: 200px;
    transition: 0.3s ease;
    position: relative;
    .filters__dropdowns-info {
        color: $jungle-green2;

        margin-left: 4px;
        div {
            box-shadow:
                0px 4px 5px rgba(0, 0, 0, 0.14),
                0px 1px 10px rgba(0, 0, 0, 0.12),
                0px 2px 4px -1px rgba(0, 0, 0, 0.2);
            background-color: #ffffff;
            position: absolute;
            transform: scale(0);
            transition: $dur $ease;
            left: -33px;
            width: 512px;
            height: 93px;
            border-radius: 8px;
            margin-top: 8px;
            display: none;
            h5 {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                margin-left: 12.05px;
                margin-top: 11px;
                color: $blue-whale;
                span {
                    color: $jungle-green2;
                    margin-right: 8.09px;
                }
            }
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                margin-left: 16.06px;
                margin-top: 16px;
                color: $boulder2;
            }
        }

        @include hover {
            div {
                z-index: 100;
                display: block;
                transform: scale(1);
            }
        }
    }
    .filters__new-feature {
        position: absolute;
        padding: 3px 0;
        display: inline-block;
        width: 92px;
        height: 22px;
        top: -1px;
        left: 112px;
        background: rgba(34, 170, 148, 0.22);
        border-radius: 12px;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        color: $jungle-green2;
    }

    .filters__new-feature-news,
    .filters__new-feature-calc {
        position: absolute;
        padding: 3px 0;
        display: inline-block;
        width: 92px;
        height: 22px;
        left: 50px;
        background: rgba(34, 170, 148, 0.22);
        border-radius: 12px;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        color: $jungle-green2;
    }

    .filters__new-feature-calc {
        width: 50px;
        left: auto;
        margin-left: 10px;
    }

    .filters__new-feature-prop {
        position: absolute;
        padding: 3px 0;
        display: inline-block;
        width: 92px;
        height: 22px;
        left: 80px;
        background: rgba(34, 170, 148, 0.22);
        border-radius: 12px;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        color: $jungle-green2;
    }

    .filters__new-feature--cooming-soon {
        left: 145px;
        background-color: rgba(#3366cc, 0.15);
        color: #3366cc;
    }

    .filters__beta {
        position: absolute;
        padding: 3px 0;
        display: inline-block;
        margin-left: 32px;
        width: 50px;
        height: 22px;
        top: -1px;
        left: 112px;
        background: rgba(34, 170, 148, 0.22);
        border-radius: 12px;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        color: $jungle-green2;
    }
    #interest-rate__new-feature {
        left: 173px;
    }
    &.new-feature {
        display: flex;
        align-items: center;
        span:first-child {
            flex-shrink: 0;
        }
        .filters__new-feature-news {
            flex-shrink: 0;
            margin-left: 12px;
            position: static;
        }
    }
}

.filters__acq-beta {
    padding: 3px 0;
    display: inline-block;
    margin-left: 10px;
    width: 50px;
    height: 22px;
    background: rgba(34, 170, 148, 0.22);
    border-radius: 12px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: $jungle-green2;
}

.site-sidebar__count-slash {
    color: $boulder;
}

.site-sidebar__count-item {
    margin-bottom: 10px;

    &:last-of-type {
        margin-bottom: 0;

        .site-sidebar__count-item-content {
            margin-bottom: 0;
        }
    }
}

.site-sidebar__logo {
    width: 196px;
    display: block;
    flex-shrink: 0;
    img {
        width: 196px;
    }
    @include mq(1200px) {
        width: 28px;
        margin-right: 30px;
    }
}

img.site-sidebar__logo--L {
    opacity: 1;
    transition: 0.5s ease;
    @include mq(1250px) {
        display: none;
    }
}

img.site-sidebar__logo--S {
    position: absolute;
    left: 23px;
    top: 20px;
    width: 28px;
    @include mq(1250px) {
        height: 36px;
    }
}

.site-header__navigation-dropdown {
    position: relative;
    margin-left: 32px;
    cursor: pointer;

    &::before {
        display: none;
        content: "";
        position: absolute;
        width: 50px;
        height: 50px;
        top: 0;
        right: 0;
    }

    &:hover {
        &::before {
            display: block;
        }
        svg {
            fill: $jungle-green;
            color: $jungle-green;
        }
    }

    svg {
        fill: $blue-whale;
        color: $blue-whale;
        height: 28px;
        transition: $dur $ease;
    }

    @media screen and (max-width: 767px) {
        margin-left: 19px;
    }
}

// bottom
.site-sidebar__count-item-title {
    color: $boulder;
    font-size: 12px;
    line-height: 14px;
    font-family: $font-primary;
}

.site-sidebar__count-item-content {
    display: flex;
    align-items: baseline;
    margin-bottom: 12px;

    p {
        line-height: 24px;
        font-family: $font-primary;
        font-size: 14px;
        color: $boulder;
        margin: 0;
        letter-spacing: -0.25px;

        &.site-sidebar__count-item-current-value {
            font-size: 20px;
            color: $blue-whale;
            font-weight: 500;
        }
    }
}

.site-sidebar__count-item-total {
    display: flex;
    align-items: center;
}

.site-sidebar__count-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 14px;
    color: $blue-whale;
    width: 515px;
    span {
        transition: 0.3s ease;
    }
    svg {
        margin-right: 10px;
        font-size: 24px;
    }
}

.main-menu-collapser {
    position: fixed;
    top: 0;
    z-index: 99999999;
    border: 0;
    background: transparent;
    padding: 5px;
    font-size: 16px;
    top: 21px;
    left: 505px;
    width: 26px;
    height: 26px;
    svg {
        position: absolute;
        left: 5px;
        top: 6px;
        &:first-child {
            transform: rotate(180deg);
        }
        &:last-child {
            opacity: 0;
            pointer-events: none;
        }
    }
    &.collapsed {
        left: 60px;
        transform: none;
        svg {
            &:first-child {
                opacity: 0;
                pointer-events: none;
            }
            &:last-child {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
    @include mq(1920px) {
        &:not(.collapsed) {
            top: 6px;
            left: -31px;
            bottom: auto;
            position: absolute;
        }
    }

    // @media only screen and (min-width: 768px) and (max-width: 1000px) {
    //     position: absolute;
    // }

    @include mq(767px) {
        display: none;
    }
}

#video_walkthrough {
    overflow-x: hidden;
    position: relative;
    padding: 8px 20px;
    height: 100%;
    overflow-y: auto;
    transition: $dur $ease;
    @include scroll-style($boulder, $alabaster, 0, 0.4vw, 0);
    @media screen and (max-width: 767px) {
        &.open {
            z-index: inherit;
            position: fixed;
            top: 70px;
            background-color: $white;
            height: calc(100vh - 70px);
            overflow: scroll;
        }
        &.closed {
            height: 0;
        }

        tbody {
            transform: translateY(-2px);
        }
    }

    // Supports query for dvh
    @supports (height: 100dvh) {
        @media screen and (max-width: 767px) {
            &.open {
                height: calc(100dvh - 70px);
            }
        }
    }
}

.sidebar__videos {
    padding-left: 8px;

    h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        color: #03363d;
        margin-bottom: 15px;
    }

    &__header-wrap {
        display: none;
        @include mq($md) {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100vw;
            padding: 0 44px 16px 30px;

            button {
                background: transparent;
                border: none;
                padding: 0;
                font-size: 10px;
                font-family: $font-primary;
                color: $jungle-green2;
                position: absolute;
                left: 0;
                top: 2px;
                padding-left: 16px;

                svg {
                    font-size: 15px;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            h3 {
                font-size: 14px;
                font-family: $font-primary;
                font-weight: 400;
                line-height: 14px;
                color: $blue-whale;
            }
        }
    }
    &__links {
        margin-bottom: 33px;
        padding-left: 12px;

        div {
            margin-top: 1px;
            a {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                color: #757575;
                padding-left: 12px;
                display: flex;
                gap: 15px;
                align-items: center;
            }

            svg {
                color: black;
            }

            &:hover {
                a {
                    color: $jungle-green;
                }
                svg {
                    color: $jungle-green;
                }
            }
        }
    }

    @include mq($md) {
        h4 {
            margin-top: 12px;
            margin-bottom: 12px;
            width: 85%;
        }

        &__links {
            div {
                margin-top: 10px;
            }
        }
    }
}
