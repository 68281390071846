/*--------------------------------------------------------------
## Find a Lender Section Style
--------------------------------------------------------------*/
.find-a-lender-iframe {
    border: none;
}

.lender__results-info {
    padding: 42px 4px 75px;
    text-align: center;
    h4 {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: $blue-whale;
        margin-bottom: 12px;

        @include mq($md) {
            font-size: 12px;
            line-height: 23px;
            margin-bottom: 0;
        }
    }
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $boulder2;
        margin-bottom: 38px;

        @include mq($md) {
            font-size: 10px;
            line-height: 22px;
            margin-bottom: 32px;
        }
    }

    @include mq($md) {
        padding: 11px 4px 30px;
    }
}

.lender-collapse {
    .ant-collapse-content-box {
        padding: 0;
    }
}

.lender-collapse--coming-soon {
    .filters__dropdown-icon,
    .ant-collapse-content {
        display: none !important;
    }

    .filters__dropdowns-icon {
        width: 24px;
        color: #757575;
        svg {
            color: #757575;
        }
    }

    .filters__dropdowns-text {
        width: auto;
        color: #757575;
    }

    .filters__new-feature--cooming-soon {
        left: auto;
        right: -102px;
    }
}

.lender-collapse--news,
.lender-collapse--calculator {
    .filters__dropdown-icon,
    .ant-collapse-content {
        display: none !important;
    }

    .filters__dropdowns-icon {
        width: 24px;
        color: $blue-whale;
        svg {
            color: $blue-whale;
        }
    }

    .filters__dropdowns-text {
        width: auto;
        color: $blue-whale;
    }
}

.lender-collapse--video {
    border-top: 1px solid #d9d9d9;
}

.lender {
    display: flex;
    flex-direction: column;
    padding: 13px 14px 0 15px;
    // height: calc(100vh - 307px);
    // height: calc(100vh - 256px);
    overflow-y: auto;
    position: relative;
    @include scroll-style($boulder, $alabaster, 0, 0.4vw);
    @media screen and (max-width: 767px) {
        &.open {
            display: block;
            position: fixed;
            z-index: inherit;
            background-color: $white;
            max-width: 100%;
            width: 100%;
            height: 100vh;
            top: 0;
            padding: 83px 0 0;
            overflow-x: hidden;
        }
        &.closed {
            display: none;
        }
    }
}

.lender__top-wrap {
    display: none;

    @include mq($md) {
        display: block;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 42px 21px;

        button {
            background: transparent;
            border: none;
            padding: 0;
            font-size: 10px;
            font-family: $font-primary;
            color: $jungle-green2;
            position: absolute;
            left: 15px;
            top: 2px;
            padding-left: 16px;

            svg {
                font-size: 15px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        h3 {
            font-size: 14px;
            font-family: $font-primary;
            font-weight: 400;
            line-height: 14px;
        }
    }
}

.lender__info-popup-wrapper {
    position: relative;
}

.lender__info-title {
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: $boulder2;

    @include mq(450px) {
        width: 100%;
    }
}

.lender__info-desc {
    display: inline-block;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: $boulder2;
    margin-left: 4px;
}

.lender-info-icon {
    position: relative;
    top: 1px;
    left: 6px;
    cursor: pointer;

    svg {
        color: $gray;
        font-size: 14px;
    }

    &.results-loaded {
        .lender-info-popup {
            left: -230px;
            &::after {
                left: auto;
                right: -10px;
                transform: rotate(180deg);
            }

            @include mq(450px) {
                top: -11px;
                left: -180px;
                width: 170px;
                padding: 10px 7px;
                font-size: 10px;
                line-height: 14px;
            }
        }
    }
    &:hover {
        .lender-info-popup {
            opacity: 1;
            visibility: visible;
        }
    }
}

.lender__result-name .lender-info-icon-tooltip {
    color: $white;
    top: -70px;
    left: -62px;
    width: 216px;
    height: 62px;

    &::after {
        left: 33.5%;
        top: auto;
        bottom: -9px;
        transform: rotateZ(33deg) translateX(-50%);
    }
}

.lender-info-popup {
    background-color: $blue-whale;
    width: 218px;
    padding: 13px 14px 10px;
    color: $white;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    display: inline-block;
    position: absolute;
    top: -13px;
    left: 30px;
    opacity: 0;
    visibility: hidden;
    user-select: none;
    pointer-events: none;
    transition: 0.3s ease;
    z-index: 1;

    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-width: 9.5px 15px 9.5px 0;
        border-color: transparent $blue-whale transparent transparent;
        border-style: solid;
        left: -9px;
        top: 13px;
    }

    &:before {
        content: "";
        position: absolute;
        border: 1px solid $blue-whale;
    }
}

.lender__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    @include mq($md) {
        padding: 0 15px;
        margin-bottom: 9px;
    }
}

.lender__filters-collapser {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -7px;
    width: 76px;
    height: 28px;
    border-radius: 4px;
    background-color: $alabaster-second;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $blue-whale;
    cursor: pointer;
    svg {
        margin-left: 8px;
        font-size: 11px;
        transition: 0.2s ease;
    }
    &.collapsed {
        svg {
            transform: rotate(180deg);
        }
    }
}

.lender__filters {
    display: flex;
    flex-direction: column;
    padding: 14px 14px 12px 9px;
    border-radius: 4px;
    background-color: $alabaster-second;
    // height: 217px;
    transition:
        opacity 0.2s ease,
        visibility 0.2s ease,
        height 0.3s ease,
        margin-bottom 0.3s ease;
    visibility: visible;
    opacity: 1;
    margin-bottom: 22px;
    &.collapsed {
        height: 0;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        margin-bottom: -26px;
    }
    &.not-paid {
        .lender__filters-row:last-of-type .form-element {
            opacity: 0.35;
        }
    }

    @include mq($md) {
        padding: 11px 15px 12px;
        border-radius: 0;
        height: 375px;
    }
}

.lender__submit-btn {
    margin-left: auto;
    margin-top: -4px;
    padding: 12px;
    background-color: $blue-whale;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $white;
    box-shadow:
        0px 1px 1px rgba(0, 0, 0, 0.14),
        0px 2px 1px rgba(0, 0, 0, 0.12),
        0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    transition: 0.2s ease;
    border: 0;
    svg {
        font-size: 16px;
        margin-right: 4px;
        margin-bottom: -2px;
    }
    &.disabled {
        background-color: rgba($blue-whale, 0.5);
        box-shadow: none;
    }
    @include hover {
        background-color: $jungle-green2;
    }

    @include mq($md) {
        margin-top: 0;
    }
}

.lender__filters-row {
    display: flex;
    position: relative;
    margin-bottom: 17px;

    &.public-origination-wrapper {
        margin-bottom: 0;
        margin-top: 8px;

        @include mq(767px) {
            flex-direction: column;
        }

        .lender-info-icon > button svg {
            color: $jungle-green2;
            font-size: 14px;
        }

        .lender-info-popup.overlay-tooltip {
            left: 65px;

            b {
                display: block;
                font-size: 14px;
                margin-bottom: 9px;
                font-weight: 500;
            }
            &::after {
                top: 39px;
            }
        }
    }

    label {
        display: inline-block;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $blue-whale;
        margin-bottom: 5px;
        margin-left: 8px;
        cursor: default;
        max-width: 324px;

        @include mq($md) {
            margin-bottom: 7px;
        }
    }

    .locked label {
        opacity: 0.5;
    }

    .form-element {
        margin-bottom: 0;
        width: 47%;
    }
    .form-element__prefix {
        font-size: 15px;
        top: 7px;
    }
    .form-element--input {
        margin-top: 0;
    }
    .ant-radio-group {
        padding-top: 5px;

        @include mq($md) {
            padding-top: 0;
        }
    }
    .ant-select {
        .ant-select-selector {
            height: 36px;
            border-radius: 4px;
            padding: 0 10px 0 12px;
            border-color: #c9cccf;
        }
        .ant-select-arrow svg {
            fill: $boulder2;
        }
        .ant-select-selection-placeholder {
            padding-top: 7px;
        }
        .ant-select-selection-item {
            display: flex;
            align-items: center;
        }
        .ant-select-selection-item div,
        .ant-select-selection-placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $boulder2;
        }
    }
    .ant-select-focused {
        .ant-select-selector {
            box-shadow: none !important;
            border-color: #c9cccf !important;
        }
    }
    .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: none;
    }
    .ant-radio-wrapper {
        margin-right: 4px;
        .ant-radio {
            top: 4px;
            border: 1px solid transparent;
            border-radius: 100%;
        }
        .ant-radio-checked {
            border-color: $blue-whale;
            .ant-radio-inner {
                border-color: $blue-whale;
            }
        }
        .ant-radio-inner {
            width: 18px;
            height: 18px;
            border-color: $silver;
            &::after {
                width: 16px;
                height: 16px;
                margin-top: -8px;
                margin-left: -8px;
                background-color: $blue-whale;
            }

            // @include mq($md) {
            //     width: 16px;
            //     height: 16px;
            // }
        }
        span:last-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $boulder2;

            @include mq(330px) {
                font-size: 12px;
            }
        }
        &:last-child {
            margin-right: 0;
            span:last-child {
                padding-right: 0;
            }
            .ant-radio-inner::after {
                margin-left: -8px;
            }
        }

        @include mq($md) {
            margin-right: 7px;
        }
    }
    & > div {
        &:first-child {
            // width: 162px;
            margin-right: 29px;
            flex-shrink: 0;
            .ant-select {
                width: 100% !important;
            }
        }
        &:last-child:not(.lender__filters-unlock, .form-element.form-element--radio) {
            width: 50%;
            .ant-select {
                width: 100% !important;
            }
        }

        @include mq($md) {
            max-width: 42.5%;
            width: 100% !important;
        }
    }

    @include mq($md) {
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 15px;
    }
}

.form-element--radio {
    @include mq($md) {
        display: flex;
        flex-direction: column;
        max-width: 100% !important;
        margin-top: 18px;

        label {
            margin-bottom: 3px;
        }
    }

    .filter-toggle {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .ant-switch-checked {
            background-color: $jungle-green2;
        }

        label {
            font-weight: 500;
            font-size: 12px;
            color: $blue-whale;
            margin-bottom: 0;
            margin-left: 10px;
        }
    }
}

.filter-toggle .ant-switch {
    background-color: #e8edf3;
    background-image: none;
    min-width: 39px;
    height: 22px;
    vertical-align: top;

    &.ant-switch-checked .ant-switch-handle::before {
        background-color: $white;
    }
}

.filter-toggle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.filter-public-label {
    max-width: 370px;
    margin-bottom: 0;
}
@media screen and (max-width: 767px) {
    .filter-public-label {
        max-width: 200px;
    }
}
.filter-toggle .ant-switch-checked {
    background-color: $jungle-green2;
}

.ant-switch-handle {
    width: 14px;
    height: 14px;
    top: 4px;
    left: 5px;
    &::before {
        background-color: #a2acba;
    }
}

.public-origination-tooltip {
    top: 26px;
    left: -78px;

    &::after {
        left: 75px;
        top: -7px;
        transform: rotate(-28deg);
    }
}

.execution-type-wrapper {
    .ant-select .ant-select-selection-placeholder {
        padding-top: 2px;

        @include mq($md) {
            padding-right: 15px;
        }
    }

    .brokers-dropdown .ant-checkbox-wrapper {
        padding: 2px 13px 0px 30px;
    }

    .ant-select-arrow {
        z-index: 2;
    }

    .ant-select-selector {
        max-width: 229px;

        @include mq($md) {
            max-width: 100%;
        }
    }

    .ant-select-multiple {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 3px;
            right: 10px;
            height: 84%;
            width: 10%;
            background-color: $white;
            filter: drop-shadow(-10px 0px 5px rgba($white, 1));
            pointer-events: none;
        }
    }

    .ant-select-selection-overflow {
        display: inline-flex;
        flex-wrap: nowrap;
        overflow: hidden;
        position: relative;
        max-width: 229px;
    }

    .ant-select-disabled {
        opacity: 0.5;
        .ant-select-selection-overflow::after {
            display: none;
        }
    }

    .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix) {
        margin-left: 3px;
        &::after {
            content: ", ";
        }

        &:nth-last-child(2):after {
            content: "";
        }
    }
}

.all-selected .ant-select-selection-overflow-item:not(:first-child) {
    display: none;
}

.all-selected .ant-select-selection-overflow-item {
    &::after {
        content: "" !important;
    }
}
// .all-execution-selected:not(:first-child) {
//     display: none;
//     &::after {
//         display: none;
//     }
// }

.lender__filters-unlock-wrapper {
    position: relative;
    .lender__filters-unlock {
        height: 100%;

        &.trial {
            height: 82px;
            width: 237px;
            left: auto;

            @include mq(767px) {
                width: 50%;

                button {
                    font-size: 10px;
                }
            }

            @include mq(374px) {
                width: 56%;
            }
        }
    }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
}

.execution-type {
    .ant-select-item {
        padding: 6px 0;
    }

    &.brokers-dropdown {
        box-shadow: none;

        .ant-checkbox-wrapper {
            padding: 4px 14px 0px 30px;
            pointer-events: none;
        }
    }
}

.form-element.form-element--radio {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.lender__filters-unlock {
    position: absolute;
    top: -7px;
    left: -5px;
    right: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 76px;
    background-color: rgba(31, 159, 138, 0.08);
    border-radius: 4px;
    button {
        background: transparent !important;
        border: none !important;
        color: $jungle-green2 !important;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        box-shadow: none;
    }
    svg {
        font-size: 13px;
        margin-right: 8px;
    }
}

.lender__results {
    overflow: auto;
    flex: 1 1 100%;
    margin: 0 -14px;
    padding: 0 14px;
    @include scroll-style($thumb-color: $boulder2, $track-color: #faf9f7, $radius: 2px, $width: 4px);
}

.lender__results-load-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 3px;

    @include mq(767px) {
        margin-bottom: 73px;
    }
}

.lender__results-count {
    margin-left: auto;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: $boulder2;
    margin-bottom: 11px;
}

.lender__results-load-more {
    display: inline-flex;
    align-self: center;
    align-items: center;
    padding: 5px 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $jungle-green2;
    margin-bottom: 35px;
    cursor: pointer;
    svg {
        margin-right: 4px;
    }
}

.lender__results-heading {
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $blue-whale;
    span {
        display: inline-block;
        &:first-child {
            width: 198px;
            margin-left: 44px;

            @include mq(450px) {
                width: 107px;
                margin-left: 22px;
            }
        }
        &:last-child {
            margin-left: 10px !important;
        }
    }

    @include mq(450px) {
        font-size: 10px;
    }
}

.lender__results-wrap {
    display: flex;
    flex-direction: column;
    margin: 0 -14px;
    padding: 0 14px;
}

.lender__best-match {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    width: 100%;
    background-color: #e3efed;
    color: $jungle-green2;
    font-size: 10px;
    svg {
        font-size: 12px;
        margin-right: 4px;
    }
}

.lender__result-item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    min-height: 56px;
    margin-bottom: 8px;
    background: $white;
    box-shadow:
        0px 1px 1px rgba(0, 0, 0, 0.25),
        0px 0px 24px rgba(0, 0, 0, 0.08);
    &.special-item {
        flex-wrap: nowrap;
        padding: 13px 16px 14px 13px;
        background: linear-gradient(90deg, #3ebfac 0%, #6fc0d5 100%);
        color: $white;

        @include mq(340px) {
            padding: 13px 6px 14px 13px;
        }
    }
    &:first-of-type {
        .lender__result-origs__hover {
            top: auto;
            bottom: -60px;
            z-index: 1;
        }
        .lender__result-details__hover {
            top: 59px;
            z-index: 1;
        }
        .lender__result-origs__hover,
        .lender__result-details__hover {
            &::after {
                top: -10px;
                transform: rotate(90deg);
            }
        }
    }

    @include mq(450px) {
        margin-bottom: 7px;
    }
}

.lender__result-become-member {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 149px;
    height: 44px;
    background: rgba(31, 159, 138, 0.08);
    border-radius: 4px;
    padding-top: 3px;
}

.lender__result-become-member__btn {
    text-align: center;
    border: 0;
    box-shadow: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: $jungle-green2;
    margin-bottom: 3px;
    svg {
        display: block;
        margin: 0 auto 2px;
        font-size: 14px;
    }
}

.lender__result-data {
    position: relative;
    display: flex;
    min-height: 56px;
    width: 100%;
}

.lender__result-rank {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    flex-shrink: 0;
    background-color: $alabaster-second;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: $blue-whale;
    &.best-match {
        background-color: #e3efed;
    }

    @include mq(450px) {
        font-size: 10px;
        width: 17px;
    }
}

.lender__result-name {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 215px;
    padding-right: 12px;

    span {
        padding-left: 12px;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: $blue-whale;

        @include mq(450px) {
            font-size: 10px;
            padding-left: 5px;
        }
    }

    .lender__result-name-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        //width: 180px;
        padding-top: 3px;
    }

    .lender__result-name-icon {
        padding: 0;
        margin-left: 0;

        svg {
            color: $gray;
            font-size: 14px;
            fill: $gray;
            width: 18px;
            height: 18px;
            padding-left: 4px;
        }
    }

    @include mq(450px) {
        width: 122px;
        padding-right: 12px;
    }
}

.lender__result-gp {
    height: 35px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 32px;
    margin-right: -12px;
    cursor: pointer;
    img {
        width: 14px;

        @include mq($sm) {
            width: 15px;
        }
    }
    &:hover {
        .lender__result-gp__popup {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }
    }
    &::before {
        content: "";
        position: absolute;
        width: 5px;
        height: 100%;
        left: 31px;
    }

    @include mq($sm) {
        width: 26px;
        margin-right: -2px;
    }
}
.lender__result-gp__popup-first {
    top: 52px !important;
}

.lender__result-gp__popup {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    width: 454px;
    position: absolute;
    padding: 16px;
    background: $white;
    box-shadow:
        0px 4px 5px rgba(0, 0, 0, 0.14),
        0px 1px 10px rgba(0, 0, 0, 0.12),
        0px 2px 4px -1px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 1;
    left: 34px;
    top: -80px;
    transition: 0.4s ease;
    a {
        color: $jungle-green2;
        @include hover {
            text-decoration: underline;
        }
    }
    p {
        color: $boulder2;
        &:first-child {
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 8px;

            @include mq($sm) {
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 5px;
            }

            @include mq(400px) {
                font-size: 10px;
                line-height: 14px;
            }
        }
        &:last-child {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;

            @include mq($sm) {
                font-size: 10px;
            }

            @include mq(400px) {
                font-size: 9px;
            }
        }
    }

    @include mq($sm) {
        width: 350px;
        padding: 15px;
        left: 25px;
    }

    @include mq(400px) {
        width: 270px;
        padding: 10px;
    }
}

.lender__result-loans {
    width: 160px;
    padding: 11px 0 9px;
    margin-left: 0 !important;
}

.lender__result-loan {
    position: relative;
    font-size: 12px;
    line-height: 8px;
    color: $jungle-green2;
    margin-bottom: 6px;
    svg {
        width: 8px;
        height: 8px;
        margin-right: 4px;
        position: relative;
        top: -2px;

        @include mq(450px) {
            top: -1px;
        }
    }
    &.disabled {
        color: $clear-cloud;
    }
    &:last-child {
        margin-bottom: 0;
    }

    @include mq(450px) {
        font-size: 10px;
    }
}

.lender__result-help {
    display: flex;
    flex-wrap: wrap;
    p {
        font-size: 10px;
        line-height: 12px;
        color: $white;
        margin-top: 4px;
        a {
            color: $white;
            text-decoration: underline;
        }

        @include mq($md) {
            width: 100%;
        }
    }
    span {
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        margin: 2px 0 0;
    }
    svg {
        font-size: 12px;
        & + span {
            margin-left: 5px;
        }
    }

    @include mq($md) {
        padding-right: 10px;
    }
}

.lender__result-help__btn {
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    color: $white;
    background-color: transparent;
    border: 1px solid $white;
    border-radius: 4px;
    padding: 8px 10px;
    flex-shrink: 0;
    transition: 0.2s ease;
    @include hover {
        background-color: $white;
        color: $blue-whale;
    }
}

.lender__result-details {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 16px;
    cursor: pointer;
    &.active {
        .lender__result-details__icon {
            background-color: $blue-whale;
            box-shadow: none;
        }
        svg {
            color: $white;
        }
    }
    &.disabled {
        opacity: 0.65;
        pointer-events: none;
        .lender__result-details__icon,
        svg {
            color: $clear-cloud;
        }
    }
}

.lender__result-details__icon {
    width: 32px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $clear-cloud;
    border-radius: 5px;
    transition: 0.3s ease;
    box-shadow: 0;
    background-color: transparent;
    svg {
        font-size: 16px;
        color: $boulder2;
        transition: 0.3s ease;
    }
    &:hover {
        background-color: $jungle-green;
        box-shadow:
            0px 1px 1px rgba(0, 0, 0, 0.14),
            0px 2px 1px rgba(0, 0, 0, 0.12),
            0px 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        border-color: transparent;
        svg {
            color: $white;
        }
        & + .lender__result-details__hover {
            visibility: visible;
            opacity: 1;
        }
    }
}

.lender__result-details__hover,
.lender__result-origs__hover {
    position: absolute;
    top: -45px;
    right: 0;
    display: inline-block;
    padding: 14px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $white;
    background-color: $blue-whale;
    width: 165px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: 0.3s ease;
    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-width: 9.5px 15px 9.5px 0;
        border-color: transparent $blue-whale transparent transparent;
        border-style: solid;
        top: 35px;
        right: 23px;
        transform: rotate(-90deg);
    }
}

.lender__result-details__hover {
    width: 190px;
    text-align: center;
}

.lender-flyout__orig-wrap {
    display: flex;
    flex-wrap: wrap;

    @include mq(400px) {
        flex-direction: column;
    }
}

.lender-flyout__orig-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-right: 75px;

    @include mq(500px) {
        flex-direction: column;
        align-items: flex-start;
        padding-right: 100px;

        .lender-flyout__orig-phone {
            margin-left: 0;
        }
    }

    @include mq(400px) {
        padding-right: 0;
    }
}

.lender-flyout__flag {
    display: inline;
    margin-left: 20px;
    margin-right: 0;
    max-width: 16px !important;
    max-height: 16px !important;
    cursor: pointer;

    @include mq($md) {
        padding-right: 18px;
    }

    @include mq(340px) {
        padding-right: 10px;
    }

    &-icon {
        position: relative;
        div {
            display: none;
            position: absolute;
            z-index: 999999999999999;
            bottom: -40px;
            left: -8px;
            padding: 8px;
            width: 167px;
            height: 34px;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            background-color: $blue-whale;
            font-weight: 400;
            color: $white;
            transition: 0.2s ease;
            font-weight: 400;
            font-family: $font-primary;
            &::before {
                content: "";
                position: absolute;
                width: 10;
                height: 8;
                border-width: 0 7px 11px 7px;
                border-color: transparent transparent $blue-whale transparent;
                border-style: solid;
                top: -9px;
                left: 10px; //137px;
            }
        }

        &:hover {
            div {
                display: block;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.lender__result-origs {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding-right: 16px;
    button {
        position: relative;
        display: flex;
        align-items: center;
        padding: 8px 10px;
        background-color: transparent;
        border: 1px solid $clear-cloud;
        border-radius: 4px;
        transition: 0.3s ease;
        box-shadow: 0;
        &:hover {
            background-color: $jungle-green;
            box-shadow:
                0px 1px 1px rgba(0, 0, 0, 0.14),
                0px 2px 1px rgba(0, 0, 0, 0.12),
                0px 1px 3px rgba(0, 0, 0, 0.2);
            border-radius: 6px;
            border-color: transparent;
            span,
            svg,
            div {
                color: $white;
                border-color: $white;
            }
            .lender__result-origs__hover {
                visibility: visible;
                opacity: 1;
            }
        }

        @include mq(450px) {
            padding: 7px 8px;
            max-width: 100px;
            width: 100%;
            min-width: 100px;
        }

        @include mq(340px) {
            padding: 7px 5px;
            max-width: 95px;
            min-width: 95px;
        }
    }
    &.disabled {
        pointer-events: none;
        opacity: 0.65;
        .lender__result-origs__icon svg,
        .lender__result-origs__text,
        .lender__result-origs__count {
            color: $clear-cloud;
        }
    }
    &.active {
        button {
            background-color: $blue-whale;
            box-shadow: none;
            border-radius: 6px;
        }
        span,
        svg,
        div {
            color: $white;
            border-color: $white;
        }
    }

    @include mq($md) {
        margin-left: auto;
    }

    @include mq(450px) {
        padding-right: 14px;
    }

    @include mq(340px) {
        padding-right: 5px;
    }
}

.lender__result-origs__hover {
    width: 200px;
    top: -60px;
    right: -32px;
    &::after {
        right: 72px;
    }
}

.lender__result-origs__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: $boulder2;
    margin-right: 6px;

    @include mq(450px) {
        font-size: 10px;
        margin-right: 7px;
    }
}

.lender__result-origs__icon {
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    svg {
        width: 11px;
        height: 11px;
        color: $boulder2;
        margin-bottom: 1.5px;

        @include mq(450px) {
            width: 13px;
            height: 13px;
        }
    }
}

.lender__result-origs__count {
    margin-left: 2px;
    font-weight: 400;
    font-size: 12px;
    line-height: 8px;
    color: $boulder2;
    font-family: $font-third;
}

.lender__loading-results {
    transition: 0.2s ease;
    height: 0;
    overflow: hidden;
    opacity: 0;
    p {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $blue-whale;
        margin-bottom: 8px;
        text-align: center;
        &:nth-of-type(2) {
            color: $boulder;
            margin-bottom: 30px;
        }
    }
    &.visible {
        height: 416px;
        overflow: initial;
        opacity: 1;
    }
}

@keyframes shimmer {
    0% {
        background-position: -150px 0;
    }
    25% {
        background-position: 150px 0;
    }
    100% {
        background-position: 150px 0;
    }
}

.lender__loading-skeleton__row {
    display: flex;
    align-items: center;
    padding: 14px 16px 12px;
    height: 58px;
    width: 100%;
    background: $white;
    box-shadow:
        0px 1px 1px rgba(0, 0, 0, 0.25),
        0px 0px 24px rgba(0, 0, 0, 0.08);
    margin-bottom: 8px;
    .box {
        position: relative;
        background-color: $clear-cloud;
        &::before {
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            background-image: linear-gradient(
                to right,
                rgba(#000, 0.03) 0%,
                rgba(#000, 0.045) 10%,
                rgba(#000, 0.065) 20%,
                rgba(#000, 0.07) 40%,
                rgba(#000, 0.065) 70%,
                rgba(#000, 0.045) 80%,
                rgba(#000, 0.03) 100%
            );
            background-repeat: no-repeat;
            background-size: 150px 40px;
            animation: shimmer 1.5s linear infinite;
            overflow: hidden;
        }
    }
    .first-box {
        width: 151px;
        height: 16px;
        margin-right: 33px;
    }
    .second-box {
        width: 102px;
        height: 30px;
        margin-right: 40px;
        background-color: transparent;
        > div.box {
            width: 62px;
            height: 12px;
        }
        .box {
            &::before {
                animation-delay: 1.65s;
            }
        }
    }
    .box-wrap {
        display: flex;
        margin-bottom: 6px;
        div {
            &:first-child {
                width: 56px;
                height: 12px;
                margin-right: 6px;
            }
            &:last-child {
                width: 40px;
                height: 12px;
            }
        }
    }
    .third-box {
        width: 108px;
        height: 28px;
        margin-right: 16px;
        &::before {
            animation-delay: 3.2s;
        }
    }
    .fourth-box {
        width: 30px;
        height: 28px;
        &::before {
            animation-delay: 4.85s;
        }
    }
}

.lender-flyout {
    position: absolute;
    z-index: 2;
    top: 70px;
    left: 210px;
    width: 500px;
    height: calc(100vh - 70px);
    background-color: $white;
    transition: 0.5s ease;
    opacity: 1;
    visibility: visible;
    transform: translateX(-171px);
    &.open {
        transform: translateX(330px);
        z-index: 2000;
    }
    &.collapsed {
        width: 0;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transform: translateX(-500px);
    }
    @media screen and (max-width: 767px) {
        display: block;
        z-index: 100;
        left: -100%;
        width: 100vw;

        &.open {
            transform: translateX(330px);

            @include mq($md) {
                transform: translateX(0);
                left: 0;
            }
        }
    }
}

.lender-flyout__header {
    display: flex;
    padding: 14px 32px 14px 20px;
    background-color: #eff7f9;
    span,
    h5,
    svg {
        color: $blue-whale;
        font-size: 14px;
    }
    span {
        font-weight: 500;
    }
    h5 {
        margin-top: 8px;
    }
    svg {
        width: 10px;
    }
}

.lender-flyout__close {
    position: absolute;
    top: 9px;
    right: 15px;
    cursor: pointer;
    padding: 5px;
    transition: 0.2s ease;
    opacity: 1;
    @include hover {
        opacity: 0.75;
    }
}

.lender-flyout__inner {
    overflow: scroll;
    height: 100%;

    @include scroll-style($thumb-color: $blue-whale, $track-color: $white, $radius: 2px, $width: 4px, $height: 25px);
}

.lender-flyout__main {
    padding: 24px 20px 90px;
    overflow: auto;
    &.lending-details {
        padding-top: 20px;
    }
}

.lender-flyout__rcmd-origs__wrap {
    height: 100%;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transition: 0.3s ease;
}

.lender-flyout__rcmd-origs {
    margin-top: 30px;
    padding-top: 32px;
    position: relative;
    &:not(.other-contacts)::before {
        content: "";
        display: block;
        width: 180px;
        height: 1px;
        background-color: $clear-cloud;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    h6 {
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: $blue-whale;
        margin-bottom: 8px;
    }
    & > p {
        color: $boulder2;
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }
    &.other-contacts {
        margin-top: 0;
        padding-top: 0;
        h6 {
            position: relative;
            display: flex;
            align-items: center;
            margin-top: 16px;
            margin-bottom: 16px;
            cursor: pointer;
            svg {
                padding: 5px;
                transition: 0.2s ease;
                transform: rotate(180deg);
            }
        }
        &.collapsed {
            overflow: hidden;
            height: 34px;
            margin-top: -16px;
            svg {
                transform: rotate(0);
            }
            .lender-flyout__rcmd-origs__wrap {
                height: 0;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
            }
        }
    }
}

.lender-flyout__rcmd-orig__inner {
    display: flex;
    align-items: center;
    .lender-flyout__orig-name {
        margin-bottom: -1px;
    }
    svg {
        margin-right: 7px;
        font-size: 12px;
    }
}

.lender-flyout__rcmd-orig {
    position: relative;
    background-color: $white;
    padding: 12px 10px 12px 12px;
    box-shadow:
        0px 1px 2px rgba(0, 0, 0, 0.3),
        0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    margin-bottom: 16px;
    button {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        background: transparent;
        border: none;
        color: $jungle-green2;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-decoration-line: underline;
        flex-shrink: 0;
        width: 100px;
        top: 50%;
        transform: translateY(-50%);
        margin-right: 8px;
        svg {
            font-size: 16px;
            margin-left: 6px;
        }

        @include mq(400px) {
            position: relative;
            right: auto;
            padding: 0;
            margin-top: 20px;
            margin-right: 0;
        }
    }
    a,
    span {
        display: block;
    }
    &.special-item {
        display: block;
        min-height: 0;
        box-shadow: none;
        padding: 0;
        p {
            font-size: 14px;
            line-height: 16px;
            color: $boulder2;
            margin-bottom: 16px;
            margin-top: 6px;
            button {
                transform: translateY(-1px);
                margin-left: 2px;
                position: unset;
            }
        }
        button {
            display: inline-block;
            width: 130px;
            padding: 0;
            text-align: left;
        }
    }
}

.lender-flyout__rcmd-lenders {
    margin-bottom: 16px;
    padding: 14px;
    background-color: #eff7f9;
    font-size: 14px;
    line-height: 18px;
    color: $blue-whale;
    a {
        font-weight: 500;
        color: $jungle-green2;
        text-decoration: underline;
    }
}

.lender-flyout__orig-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $blue-whale;
    margin-bottom: 4px;
}

.lender-flyout__orig-phone {
    margin-left: 12px;

    @include mq(400px) {
        margin: 0;
    }
}

.lender-flyout__orig-email,
.lender-flyout__orig-phone,
.lender-flyout__orig-star{
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $jungle-green2;
    svg {
        margin-right: 6px;
    }
    &:hover {
        color: $jungle-green2;
        text-decoration: underline;
    }
}

.lender-flyout__detail {
    h6 {
        color: $blue-whale;
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
    }
    p {
        color: $boulder2;
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 19px;
    }
}

.lender-flyout__detail-wrap {
    display: flex;
    .lender-flyout__detail {
        width: calc(50% - 9px);
        &:first-child {
            margin-right: 18px;
        }
    }
}

.public-lender-toggle-lock-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .lender__filters-unlock {
        top: 2px;
        left: -6px;
        right: 0;
        width: 49px;
        height: 30px;

        svg {
            margin-right: 0;
        }
    }

    &.locked .filter-toggle {
        pointer-events: none;
        opacity: 0.5;
    }

    @include mq(767px) {
        margin-bottom: 15px;
        max-width: 100% !important;
        margin-right: 0 !important;
    }

    @include mq(349px) {
        .filter-toggle {
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;
        }
    }
}

.calculator-mini-popup__skeleton {
    position: relative;
    margin: 0 auto 32px;
    width: 340px;
    height: 33px;
    background-color: rgba($clear-cloud, 0.2);
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
            to right,
            rgba(#000, 0.025) 0%,
            rgba(#000, 0.04) 10%,
            rgba(#000, 0.06) 20%,
            rgba(#000, 0.065) 40%,
            rgba(#000, 0.06) 70%,
            rgba(#000, 0.04) 80%,
            rgba(#000, 0.025) 100%
        );
        background-repeat: no-repeat;
        background-size: 500px 33px;
        animation: shimmer 1.7s linear infinite;
    }
}
.find-a-lender-block-access-from-sidebar {
    position: absolute;
    background: white;
    width: 97%;
    height: 98%;
    padding: 5%;
    div {
        text-align: center;

        h4 {
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            color: $blue-whale;
            margin-bottom: 12px;

            @include mq($md) {
                font-size: 12px;
                line-height: 23px;
                margin-bottom: 0;
            }
        }
        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $boulder2;
            margin-bottom: 38px;

            @include mq($md) {
                font-size: 10px;
                line-height: 22px;
                margin-bottom: 32px;
            }
        }
    }

    @include mq($md) {
        padding: 11px 4px 30px;
    }
    &.hide {
        display: none;
    }
}

.find-a-lender-slideout__title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #03363d;
    margin-top: 24px;
    text-align: center;
}
.find-a-lender-slideout__text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #757575;
    margin-top: 8px;
    text-align: center;
    span {
        align-items: center;
        justify-content: center;
        font-family: "ABCMonumentGrotesk", Arial, Helvetica, sans-serif;
        outline: none;
        font-weight: 700;
        width: 100%;
        background-color: #03363d;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        transition: 300ms ease;
        color: #1f9f8a;
        background-color: #fff;
    }
}
